
import { ScoleDetailWithAllInfo } from 'momai'
import { ref } from 'vue'
import { useLoadList } from '@/utils/useLoadList'
import { checkScoreDetail, pageScoreDetail } from '@/api/user/student'
import { useRouter } from 'vue-router'
import { Dialog } from 'vant'
export default {
  setup: () => {
    const scoreDetails = ref<ScoleDetailWithAllInfo[]>([])
    const totalNum = ref(-1)
    const router = useRouter()
    return {
      ...useLoadList({
        list: scoreDetails,
        total: totalNum,
        fetch: (page, loadEnd) => {
          pageScoreDetail(page).then(({ records, total }) => {
            scoreDetails.value.push(...records)
            totalNum.value = total
            loadEnd()
          })
        }
      }),
      scoreDetails,
      getEventTitle: (sd: ScoleDetailWithAllInfo) => {
        return new Map([
          ['0', '订单购买'],
          ['1', '导师批改作业'],
          ['2', '分享课程'],
          ['3', '分享活动产品'],
          ['4', '管理员操作'],
          ['5', '每日打卡']
        ]).get(sd.eventType)
      },
      getDisplayName: (sd: ScoleDetailWithAllInfo) => {
        switch (sd.eventType) {
          case '0':
            return sd?.orderInfo?.detail
          case '1':
            return sd?.homework?.workName
          case '2':
            return sd?.product?.title
          case '3':
            return sd?.course?.courseName
          case '4':
            return `${sd.type === '0' ? '赠送' : '扣除'}积分`
        }
      },
      gotoDetail: (sd: ScoleDetailWithAllInfo) => {
        checkScoreDetail(sd.id).then(() => {
          sd.checked = 1
          const go = new Map([
            ['0', () => {
              const oi = sd.orderInfo
              router.push(oi.type === 0 ? `/sales/product/${oi.productId}` : `/coursedetail/${oi.productId}`)
            }],
            ['1', () => {
              router.push(`/homework/detail/${sd.homework?.id}`)
            }],
            ['2', () => {
              router.push(`/sales/product/${sd.eventId}`)
            }],
            ['3', () => {
              router.push(`/coursedetail/${sd.eventId}`)
            }],
            ['4', () => {
              Dialog({
                title: '信息',
                message: '管理员操作',
                showCancelButton: false
              }).then(() => {})
            }],
            ['5', () => {
              router.push(`/daily`)
            }],
          ]).get(sd.eventType)
          if (go) {
            go()
          }
        })
      }
    }
  }
}
